import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "react-bootstrap/Container"

const PrivacyPage = () => (
  <Layout>
    <SEO title="Privacy" />
    <Container className="p-5">
      <h2>Privacy Policy</h2>
      <p className="mt-5">
        Some user data is collected on gridentify.com. This data includes the
        following:
      </p>
      <ul>
        <li>
          Your IP address, browser type, operating system type, hardware model
          (if available), language and region, screen dimensions, browser
          dimensions, etc.
        </li>
      </ul>
    </Container>
  </Layout>
)

export default PrivacyPage
